<template>
  <div :style="isScroll ? 'opacity:1' : ''" class="bottom-float">
    <div class="phone">
      <img class="img" @click="clickPhone()" src="@/assets/icon/call.png" alt />
      <span>电话咨询</span>
    </div>
    <div class="wx" @click="clickWx">
      <img class="img" src="@/assets/icon/wechat.png" alt />
      <span>关注微信</span>
    </div>
    <div class="toTop">
      <img
        @click="clickNavs()"
        class="img"
        src="@/assets/icon/Download.png"
        alt
      />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestory() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      isScroll: false,
    };
  },
  methods: {
    // 滚动监听函数
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isScroll = scrollTop ? true : false;
    },
    clickNavs() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
    clickPhone() {},
    clickWx() {
      this.$store.commit("LOADING", ["", "follow"]);
      this.$store.commit("SET_ISSHOW", ["", "follow"]);
    },
  },
};
</script>

<style lang="less" scoped>
.bottom-float {
  position: fixed;
  bottom: 8px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-end;
  font-size: 14px;
  color: #ffffff;
  line-height: 28px;
  z-index: 1000;
  transition: all 0.3s ease-out;
  opacity: 0;
}

.phone {
  display: flex;
  align-items: center;
  width: 132px;
  height: 36px;
  background: #007aff;
  border-radius: 20px;
}
.wx {
  display: flex;
  align-items: center;
  width: 132px;
  height: 36px;
  background: #09bb07;
  border-radius: 20px;
}
.toTop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  .img {
    margin: 0;
  }
}
.img {
  width: 28px;
  height: 28px;
  margin: 0 8px 0 16px;
}
</style>
