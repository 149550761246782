<template>
  <div class="number">
    <div data-aos="fade-up" data-aos-offset="10">
      <h2>数字化驱动 助力企业升级</h2>
      <p>广东创世科技 更高效的企业场景解决方案提供商</p>
    </div>
    <div class="main">
      <div
        class="box"
        v-for="(item, index) in list"
        :key="index"
        :style="item.bgImg"
        :class="item.isClick ? 'clickBox' : ''"
        @click="submit(index)"
      >
        <div :class="item.isClick ? 'close' : 'open'"></div>
        <div>
          <div class="text1" :style="item.textImg">
            <span class="txt8">{{ item.title }}</span>
            <span class="txt9">{{ item.info }}</span>
            <div class="more">
              <span class="info7">了解更多</span>
              <img :src="require('../../assets/icon/ArrowRightWhite@2x.png')" />
            </div>
          </div>
          <div class="text2">
            <span class="txt8">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          isShow: true,
          isClick: false,
          title: "关于创世",
          info: "广东创世科技有限公司，成立于2017年，致力提供产业的整体技术解决方案，产学研成果转化，助力企业技术及产品研发升级。",
          bgImg:
            "background:url(https://file.chskj.cn/digitization1.jpg) no-repeat; background-size: cover; ",
          textImg:
            "background:url(https://file.chskj.cn/numsImg1.png) no-repeat; background-size: cover; ",
        },
        {
          isShow: true,
          isClick: false,
          title: "数字科学实验室",
          info: "由华南理工大学计算机学院联合华南理工大学广州校友会、欢聚集团(NASDAQ:YY)、广东创世科技等企业联合共建。实验室内设多个研究中心、技术委员会和教师工作室，依托高校。",
          bgImg:
            "background:url(https://file.chskj.cn/digitization2.jpg) no-repeat; background-size: cover; ",
          textImg:
            "background:url(https://file.chskj.cn/numsImg2.png) no-repeat; background-size: cover; ",
        },
        {
          isShow: true,
          isClick: false,
          title: "研发背景",
          info: " 数字科学实验室以大数据、云计算、人工智能、信息安全等关键技术为研究重心。通过使用使用高效的企业管理方法及先进的技术支撑，依托资深行业专家及丰富的高校人才，助力企业技术及产品研发升级。",
          bgImg:
            "background:url(https://file.chskj.cn/digitization3.jpg)  no-repeat; background-size: cover; ",
          textImg:
            "background:url(https://file.chskj.cn/numsImg3.png)  no-repeat; background-size: cover; ",
        },
      ],
    };
  },
  methods: {
    submit(index) {
      this.list[index].isClick = !this.list[index].isClick;
      this.list[index].isShow = !this.list[index].isShow;
      let txt1 = document.getElementsByClassName("text1")[index];
      let txt2 = document.getElementsByClassName("text2")[index];
      if (this.list[index].isShow) {
        txt2.style.display = "block";
        txt1.style.display = "none";
      } else {
        txt2.style.display = "none";
        txt1.style.display = "block";
      }
      if (this.list[index].isClick) {
        this.list.forEach((item, i) => {
          if (index !== i) {
            item.isClick = false;
            item.isShow = true;
            document.getElementsByClassName("text1")[i].style.display = "none";
            document.getElementsByClassName("text2")[i].style.display = "block";
          }
        });
      }
    },
  },
  computed: {
    img() {
      return function (index) {
        return index + "";
      };
    },
  },
};
</script>

<style lang="less" scoped>
.number {
  margin-top: 24px;
  margin-bottom: 24px;
  h2 {
    height: 64px;
    font-size: 28px;
    font-weight: 600;
    line-height: 64px;
    text-align: center;
  }
  p {
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }
}

.main {
  margin-top: 24px;
}
.box {
  width: 100%;
  height: 160px;
  color: #ffffff;
  position: relative;
  transition: all 0.5s ease;
  overflow: hidden;
  .open {
    width: 28px;
    height: 28px;
    background: url("~@/assets/icon/add (1).png") no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 24px;
    right: 16px;
    z-index: 999;
  }
  .close {
    width: 28px;
    height: 28px;
    background: url("~@/assets/icon/add (2).png") no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 24px;
    right: 16px;
    z-index: 999;
  }
  .text1 {
    padding: 0 16px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0px;
    display: none;
  }
  .text2 {
    padding: 0 16px;
    position: absolute;
    top: 88px;
    display: block;
  }
  .txt8 {
    font-size: 32px;
    font-weight: 600;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    display: block;
    line-height: 48px;
    padding-top: 16px;
  }
  .txt9 {
    height: 120px;
    font-size: 13px;
    font-weight: 400;
    padding-top: 6px;
    line-height: 28px;
    display: block;
  }
  .more {
    display: flex;
    align-items: center;
    .info7 {
      height: 28px;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
    }
    img {
      width: 28px;
      height: 28px;
      object-fit: cover;
    }
  }
}
.clickBox {
  height: 234px;
}
</style>
