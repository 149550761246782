<template>
  <div class="productExa">
    <div class="title" data-aos="fade-up">
      <div class="title">政企信赖之选<br />让数字化触手可及</div>
      <div class="fakeTitle">
        使用前沿先进技术，<br />完美解决用户痛点，打造经典案例
      </div>
    </div>
    <div class="swiper">
      <product-swiper />
    </div>
  </div>
</template>

<script>
import { ProductSwiper } from "@/components/";
export default {
  name: "productExa",
  components: { ProductSwiper },
};
</script>

<style scoped lang="less">
.productExa {
  .title {
    text-align: center;
    margin-top: 48px;
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
  }
  .fakeTitle {
    text-align: center;
    font-size: 16px;
    line-height: 36px;
  }
  .swiper {
    position: relative;
    margin-top: 24px;
    margin-bottom: 20px;
    padding: 0 16px;
  }
}
</style>
