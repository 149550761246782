<template>
  <div class="banner">
    <div
      v-if="bannerList.length"
      class="banner-content"
      :style="'background-image:url(' + bannerList[swiperNum].m_pic + ')'"
    >
      <p class="text36 title">{{ bannerList[swiperNum].one_title }}</p>
      <p class="text36">{{ bannerList[swiperNum].two_title }}</p>
      <p class="text14 p">{{ bannerList[swiperNum].describe }}</p>
      <div class="more" @click="more()">
        <span>了解更多</span>
        <img :src="require('@/assets/icon/ArrowRightBlue@2x.png')" alt />
      </div>
      <ul class="point-box" v-if="bannerList.length >= 2">
        <li
          v-for="(item, index) in bannerList"
          :key="index"
          :class="['point-item', index === swiperNum ? 'active' : '']"
          @click="clickPoint(index)"
        ></li>
      </ul>
    </div>
    <div class="banner-before" v-else></div>
    <ul class="banner-bottom">
      <li
        v-for="(item, index) in bannerTextList"
        :key="index"
        class="banner-bottom-item"
      >
        <span class="text16">{{ item.title }}</span>
        <span class="text11">{{ item.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
      bannerTextList: [
        {
          title: "系列产品",
          text: "多年持续研发投入，打造自主可控的系列产品组合",
        },
        { title: "解决方案", text: "场景化、智慧化的一站式企业数字化解决方案" },
        {
          title: "专业服务",
          text: "从咨询规划、研发集成到运维运营的端到端专业服务",
        },
      ],
      swiperNum: 0,
    };
  },
  created() {
    this.getBanners();
  },
  mounted() {},
  beforeDestory() {
    clearInterval(this.swiperTime);
  },
  methods: {
    // 获取banner数据
    async getBanners() {
      const res = await this.api.getBanners();
      if (res) {
        this.bannerList = res.data;
        const image = new Image();
        image.src = this.bannerList[0].m_pic;
        image.onload = () => {
          // 加载完毕
          this.$store.commit("SET_BLACK_BACK", true);
          this.swiperTime =
            this.bannerList.length >= 2
              ? setInterval(this.swiperInterval, 5000)
              : null;
        };
      }
    },
    // 轮播图点点击事件
    clickPoint(index) {
      clearInterval(this.swiperTime);
      this.changBg(index);
      this.swiperTime = setInterval(this.swiperInterval, 5000);
    },
    // 切换
    changBg(index) {
      this.swiperNum = index;
      this.bannerList.forEach((item, idx) => {
        item.active = false;
        if (index === idx) item.active = true;
      });
    },
    // 定时器函数
    swiperInterval() {
      this.changBg((this.swiperNum + 1) % this.bannerList.length);
    },
    // 了解更多
    more() {
      const base =
        this.bannerList[this.swiperNum].url.search("http") === -1
          ? "http://"
          : "";
      window.open(base + this.bannerList[this.swiperNum].url);
    },
  },
};
</script>

<style lang="less" scoped>
.banner-before {
  height: 414px;
}
.banner-content {
  position: relative;
  box-sizing: border-box;
  padding-left: 16px;
  width: 100%;
  height: 414px;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease-out;
  .title {
    padding-top: 102px;
  }
  .more {
    margin-top: 8px;
    width: 130px;
    height: 40px;
    background: #ffffff;
    border-radius: 24px;
    color: #0068da;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 28px;
      height: 28px;
      object-fit: cover;
    }
  }
}
.point-box {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  .point-item {
    width: 40px;
    height: 6px;
    background: rgba(255, 255, 255, 0.55);
    margin: 0 8px;
    border-radius: 3px;
    cursor: pointer;
  }
  .active {
    background: #ffffff;
  }
}
.banner-bottom {
  display: flex;
  .banner-bottom-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 130px;
    padding: 0 12px;
    &:nth-of-type(even) {
      background: #2b4c79;
    }
    &:nth-of-type(odd) {
      background: #305587;
    }
    .text16 {
      margin-top: 16px;
      font-size: 16px;
      line-height: 32px;
      color: #fff;
    }
    .text11 {
      font-size: 11px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
}
.text36 {
  font-size: 36px;
  line-height: 60px;
  color: #fff;
}
.text14 {
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  // width: 343px;
}
</style>
