<template>
  <div class="solutions">
    <div class="title-container" data-aos="fade-up">
      <div class="title">行业解决方案</div>
      <div class="desc">
        "AI + 大数据 + 云计算" 融合构建更高效的
        <br />政校企数字化场景解决方案
      </div>
    </div>
    <div class="items-container">
      <div class="item" v-for="(item, index) in itemList" :key="index">
        <div class="item-title">{{ item.title }}</div>
        <div class="item-desc">{{ item.desc }}</div>
        <div class="more">
          了解更多
          <img :src="require('@/assets/icon/ArrowRightWhite@2x.png')" alt />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemList: [
        {
          title: "政务数字化",
          desc: "创世科技借助大数据、云计算以及人工智能等前沿技术进一步助力政府政务转型升级，在智慧社区、智慧政务等方面提供多项优质解决方案",
        },
        {
          title: "智能制造工业4.0",
          desc: "创世科技致力于提供智能制造产业整体技术解决方案，助力企业技术及产品研发升级。将大数据、云计算以及物联网等多种技术融合到工业制造领域",
        },
        {
          title: "智慧城市",
          desc: "通过构建大规模城市级三维实景模型，建立综合运营管理平台，解决了当前城市运营发展中面临的业务协调困难、多元化市场需求支撑困难等问题与挑战",
        },
        {
          title: "智慧教育",
          desc: "创世科技集成大数据分析以及先进的软件技术于一体化考务系统及应用，通过数据分析实时反馈潜在问题，实现线上教育智能化",
        },
        {
          title: "校企合作产学研用",
          desc: "创世科技探索产学研用道路的最优合作方式，以市场需求为主导，联合科研平台以及高校申报、承担国家和地方各类科技计划项目",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.solutions {
  background: url(https://file.chskj.cn/solution.png) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-container {
    padding: 48px 8% 0 8%;
    .title {
      width: 216px;
      height: 64px;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 64px;
    }
    .desc {
      width: 311px;
      height: 56px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
    }
  }
  .items-container {
    padding-bottom: 80px;
    .item {
      width: 343px;
      height: 218px;
      background: rgba(0, 0, 0, 0.45);
      border-radius: 16px;
      border: 2px solid #ffffff;
      margin: 16px 0 0 0;
      .item-title {
        height: 42px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
        margin: 24px 5% 0 5%;
      }
      .item-desc {
        height: 84px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        margin: 8px 5% 0 5%;
      }
      .more {
        margin: 5%;
        flex: 1;
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 28px;
          height: 28px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
