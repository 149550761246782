<template>
  <div class="swiperCom">
    <swiper :options="swiperOption">
      <div class="swiper-pagination" slot="pagination"></div>
      <swiper-slide
        class="swiper-slide"
        v-for="(item, index) in productInfo"
        :key="index"
      >
        <div class="item">
          <img class="mainPic" :src="item.rightPic" />
          <div class="itemInner">
            <div class="tags">
              <span class="tag">{{ item.tag1 }}</span>
              <span class="tag">{{ item.tag2 }}</span>
            </div>
            <div class="title">
              <div class="icon">
                <img class="iconPic" :src="item.lefticon" />
              </div>
              <div class="titleCon">
                <span>{{ item.lefttitle }}</span>
                <span>{{ item.lefttext }}</span>
              </div>
            </div>
            <div class="description">{{ item.descrip }}</div>
            <div class="detail">
              <div class="detailInner">
                <img src="@/assets/icon/success.png" />
                <span>{{ item.detail1 }}</span>
              </div>
              <div class="detailInner">
                <img src="@/assets/icon/success.png" />
                <span>{{ item.detail2 }}</span>
              </div>
              <div class="detailInner">
                <img src="@/assets/icon/success.png" />
                <span>{{ item.detail3 }}</span>
              </div>
            </div>
            <div class="button">
              <span class="left">了解更多</span>
              <div class="right"><img src="@/assets/icon/right.png" /></div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "ProductSwiper",
  data() {
    return {
      productInfo: [
        {
          lefticon: "https://file.chskj.cn/product (1).png",
          lefttitle: "广州市政务服务数据管理系统",
          lefttext: "广州市政务服务数据管理局",
          rightPic: "https://file.chskj.cn/product (1).jpg",
          tag1: "政务",
          tag2: "数据服务管理平台",
          righttitle: "广州市政务服务数据管理系统",
          righttext: "广州市政务服务数据管理局",
          descrip:
            "广州市政务服务数据管理系统帮助政府实现智慧化政务，实现系统数据自治性和高效性",
          detail1: "政务处理效率提高80%",
          detail2: "内部信息流转速度提高90%",
          detail3: "实现政务可视化管理和实时沟通",
        },
        {
          lefticon: "https://file.chskj.cn/product (2).png",
          lefttitle: "广州12345政府服务热线项目",
          lefttext: "广州市政务服务数据管理局",
          rightPic: "https://file.chskj.cn/product (2).jpg",
          tag1: "政务",
          tag2: "智能智慧库",
          righttitle: "广州12345政府服务热线项目",
          righttext: "广州市政务服务数据管理局",
          descrip:
            "智能智慧库，坐席助手文本机器人平台，帮助12345热线有效落实政务服务监督，切实解决政府热线面对的顽疾",
          detail1: "减少70%工单流转时间，提升问题处理效率",
          detail2: "平台的FAQ和知识图谱，加快知识生成的速度，实现100%质检",
          detail3: "质检处理速度相较于人工处理提高了10倍",
        },
        {
          lefticon: "https://file.chskj.cn/product (3).png",
          lefttitle: "机器人公司ERP",
          lefttext: "机器人公司",
          rightPic: "https://file.chskj.cn/product (3).jpg",
          tag1: "机器人",
          tag2: "人工智能",
          righttitle: "机器人公司ERP",
          righttext: "机器人公司",
          descrip:
            "让企业管理协同效率提升400%，助力企业管理决策，实现高效系统集成、业务协同、进度跟踪、智能数据分析以及工作流自定义，极大提升企业内部事务处理效率。",
          detail1: "开放API覆盖整个企业业务，能深度进行系统集成",
          detail2: "ERP与OA打通，实时录入业务单据，实现高效业务协同",
          detail3: "支持多维度业务数据分析、支撑决策，实现高效管理",
        },
        {
          lefticon: "https://file.chskj.cn/product (4).png",
          lefttitle: "三维实景智慧城市平台",
          lefttext: "某客户",
          rightPic: "https://file.chskj.cn/product (4).jpg",
          tag1: "智慧城市",
          tag2: "三维建模",
          righttitle: "三维实景智慧城市平台",
          righttext: "某客户",
          descrip:
            "实现城市基础设施、地下管线可视化管理以及灾情模拟等动作演练，达到城市全局智慧管理，成功构建三大城市级模型：东莞城市级模型，深圳市坪山区，广州市黄埔区科学城。",
          detail1: "直观显示城市设施情况，叠加智能应用进行管理，效率大大提高",
          detail2: "城市地下管线等情况亦能通过智能应用直接在模型上显示和管理",
          detail3: "模拟紧急情况，为反恐及应急指挥提供强有力支持",
        },
        {
          lefticon: "https://file.chskj.cn/product (5).png",
          lefttitle: "三维智慧文博平台",
          lefttext: "某客户",
          rightPic: "https://file.chskj.cn/product (5).jpg",
          tag1: "文博",
          tag2: "三维建模",
          righttitle: "三维智慧文博平台",
          righttext: "某客户",
          descrip:
            "解决线上文博展示痛点，辅助构建全真实三维模型，达到完全真实的三维效果，细微纹路应收尽收，底蕴特显",
          detail1: "独家专利零门槛全自动拍摄装置，360度自动环绕、多镜头拍摄",
          detail2: "解决传统三维模型效率低成本高，效果失真等问题",
          detail3: "可结合多种应用编辑、展现便于多人管理与互联网传播展示",
        },
        {
          lefticon: "https://file.chskj.cn/product (6).png",
          lefttitle: "剑桥英语考务系统",
          lefttext: "广东省教育考试院",
          rightPic: "https://file.chskj.cn/product (6).jpg",
          tag1: "考务",
          tag2: "报名管理系统",
          righttitle: "剑桥英语考务系统",
          righttext: "广东省教育考试院",
          descrip:
            "解决剑桥英语考试报名考务痛点，结合考生报名网页及考官考务管理小程序，大大提高考试整体流程效率",
          detail1: "考试报名流程线上化（考生+考官） ",
          detail2: "实现考务平台与考点信息流转速度提高90%",
          detail3: "实现考务可视化管理",
        },
      ],
      swiperOption: {
        // swiper 的配置
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style scoped lang="less">
.item {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  margin: 0 5px;
  border-radius: 8px;
  overflow: hidden;
  .mainPic {
    width: 100%;
    height: auto;
  }
  .itemInner {
    display: flex;
    flex-direction: column;
    height: 410px;
    overflow: hidden;
    padding: 0 16px;
    .tags {
      margin-top: 8px;
      .tag {
        display: inline-block;
        height: 24px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 24px;
        padding: 0 8px;
        background: #ffcc00;
        margin-right: 8px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 16px;
      .icon {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        .iconPic {
          width: 100%;
          height: 100%;
        }
      }
      .titleCon {
        margin-left: 16px;
        span:first-child {
          display: inline-block;
          font-size: 16px;
          line-height: 24px;
        }
        span:last-child {
          display: inline-block;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .description {
      margin-top: 16px;
      font-size: 12px;
      line-height: 24px;
    }
    .detail {
      margin-top: 12px;
      .detailInner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 18px;
          height: 18px;
        }
        span {
          margin-left: 4px;
          font-size: 12px;
          line-height: 28px;
        }
      }
    }
    .button {
      margin-top: 16px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        font-size: 14px;
        line-height: 28px;
        color: #007aff;
      }
      .right {
        width: 28px;
        height: 28px;
      }
    }
  }
}
/deep/ .swiper-container {
  min-height: 100px;
}
/deep/ .swiper-pagination {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 8px;
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #0068da;
  }
  .swiper-pagination-bullet-active {
    border: none;
    width: 24px;
    height: 8px;
    padding: 1px 0;
    border-radius: 4px;
    background: linear-gradient(90deg, #0167ff 0%, #4ae0f6 100%);
  }
}
/deep/ .swiper-slide {
  top: 32px;
  padding-bottom: 40px;
}
</style>
