<template>
  <div class="news">
    <div class="title" data-aos="fade-up">
      <h1 class="h1">最新动态</h1>
      <p class="p">记录创世科技助力政企的每一瞬间</p>
    </div>
    <swiper class="news-box" :options="swiperOptions">
      <swiper-slide
        class="news-item"
        v-for="(item, index) in list"
        :key="index"
      >
        <img :src="item.pic" alt class="img" />
        <div class="text">
          <div class="time">
            <span class="letf">{{ item.day }}</span>
            <span class="right">
              <span class="mon">{{ item.mon }}月</span>
              <span class="day">{{ item.year }}</span>
            </span>
          </div>
          <div class="unline"></div>
          <p class="content">{{ item.title }}</p>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      swiperOptions: {
        speed: 1000,
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  created() {
    // 渲染前获取数据
    this.getNews();
  },
  methods: {
    // 获取动态新闻数据
    async getNews() {
      const res = await this.api.getNews();
      if (res) {
        console.log(res);
        res.data.forEach((item) => {
          const arr = item.time.split("/");
          item.year = arr[0];
          item.mon = arr[1];
          item.day = arr[2];
        });
        this.list = res.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.news {
  background-color: #305587;
  background-image: linear-gradient(to right, #fff, #fff);
  background-size: 100% 250px;
  background-repeat: no-repeat;
  user-select: none;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    .h1 {
      font-size: 36px;
      font-weight: 600;
      line-height: 56px;
    }
    .p {
      font-size: 18px;
      line-height: 48px;
    }
  }
  .news-box {
    height: 442px;
    .news-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      .img {
        width: 343px;
        height: 215px;
        background: #fff;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
      }
      .text {
        box-sizing: border-box;
        padding: 0 20px;
        background-color: #fff;
        width: 343px;
        height: 159px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
        .time {
          margin-top: 11px;
          display: flex;
          .letf {
            margin-right: 8px;
            font-size: 40px;
            font-weight: 600;
            line-height: 56px;
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
            line-height: 16px;
          }
        }
        .unline {
          width: 32px;
          height: 4px;
          background: #0054e1;
          border-radius: 3px;
          margin-bottom: 16px;
        }
        .content {
          font-size: 14px;
          line-height: 24px;
          padding-bottom: 24px;
        }
      }
    }
  }
  .swiper-pagination {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    /deep/.swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      opacity: 1;
      background: rgba(255, 255, 255, 0.25);
    }
    /deep/.swiper-pagination-bullet-active {
      width: 24px;
      height: 8px;
      background: rgba(255, 255, 255, 0.85);
      border-radius: 4px;
    }
  }
}
</style>
