<template>
  <div class="consulting">
    <div class="titleContainer" data-aos="fade-up">
      <span class="contact-title">联系我们</span>
      <p class="description">
        如需了解详情，您可直接留言咨询，我们将会为您提供更多信息与资料
      </p>
    </div>
    <span class="require">* 以下均为必填项</span>
    <div class="form">
      <div class="row">
        <span class="title">您的姓名：*</span>
        <span class="illegal" v-show="isSubmit && !validateObj.name(form.name)"
          >请输入您的姓名</span
        >
      </div>
      <div class="row inputArea">
        <input
          class="inputDiv"
          :class="isSubmit && !validateObj.name(form.name) ? 'emptyInput' : ''"
          v-model="form.name"
          placeholder="请输入您的姓名"
          maxlength="20"
        />
      </div>
      <div class="row">
        <span class="title">公司名称：*</span>
        <span
          class="illegal"
          v-show="isSubmit && !validateObj.company(form.company)"
          >请输入公司名称，20个中文字以内</span
        >
      </div>
      <div class="row inputArea">
        <input
          class="inputDiv"
          :class="
            isSubmit && !validateObj.company(form.company) ? 'emptyInput' : ''
          "
          v-model="form.company"
          placeholder="请输入公司名称"
          maxlength="20"
        />
      </div>
      <div class="row">
        <span class="title">所在城市：*</span>
        <span class="illegal" v-show="isSubmit && !validateObj.city(form.city)"
          >请输入您所在城市</span
        >
      </div>
      <div class="row inputArea">
        <input
          class="inputDiv"
          :class="isSubmit && !validateObj.city(form.city) ? 'emptyInput' : ''"
          v-model="form.city"
          placeholder="请输入所在城市"
          maxlength="15"
        />
      </div>
      <div class="row">
        <span class="title">所属行业：*</span>
        <span
          class="illegal"
          v-show="isSubmit && !validateObj.industry(form.industry)"
          >请输入所属行业，20个中文字以内</span
        >
      </div>
      <div class="row inputArea">
        <input
          class="inputDiv"
          :class="
            isSubmit && !validateObj.industry(form.industry) ? 'emptyInput' : ''
          "
          v-model="form.industry"
          placeholder="请输入所属行业"
          maxlength="20"
        />
      </div>
      <div class="row">
        <span class="title">联系电话：*</span>
        <span
          class="illegal"
          v-show="isSubmit && !validateObj.phone(form.phone)"
          >请输入联系人手机号码</span
        >
      </div>
      <div class="row inputArea">
        <input
          class="inputDiv"
          :class="
            isSubmit && !validateObj.phone(form.phone) ? 'emptyInput' : ''
          "
          v-model="form.phone"
          type="number"
          @input="onInput"
          placeholder="请输入联系电话"
        />
      </div>
      <div class="row">
        <span class="title">电子邮箱：*</span>
        <span
          class="illegal"
          v-show="isSubmit && !validateObj.email(form.email)"
          >请输入正确电子邮箱</span
        >
      </div>
      <div class="row inputArea">
        <input
          class="inputDiv"
          :class="
            isSubmit && !validateObj.email(form.email) ? 'emptyInput' : ''
          "
          v-model="form.email"
          placeholder="请输入电子邮箱"
        />
      </div>
      <div class="row">
        <span class="title">留言内容：*</span>
        <span
          class="illegal"
          v-show="isSubmit && !validateObj.content(form.content)"
          >请输入咨询内容，300中文字以内</span
        >
        <span
          :class="['textLength', form.content.length > 0 ? 'textVisible' : '']"
          >已输入{{ form.content.length }}个字符</span
        >
      </div>
      <div class="row textArea">
        <textarea
          :class="[
            'inputDiv',
            isSubmit && !validateObj.content(form.content) ? 'emptyInput' : '',
          ]"
          v-model="form.content"
          placeholder="请输入您想咨询的问题"
          maxlength="300"
        />
      </div>
      <div class="row inputArea-1">
        <input
          class="inputDiv codeDiv"
          v-model="codeInput"
          :class="codeInput === '' && isSubmit ? 'emptyInput' : ''"
          placeholder="请输入验证码"
        />
        <div class="code" @click="refreshCode"></div>
      </div>
      <div class="row submit">
        <button class="submitButton" @click="submitForm">提交</button>
      </div>
    </div>
    <div class="methods">
      <div class="title">您也可以通过以下方式与我们联系</div>
      <div class="row">
        <img src="https://file.chskj.cn/contactWechat.png" alt />
        <a
          href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI3MzE0Mzk0MQ==&scene=124#wechat_redirect"
          >数字科学实验室</a
        >
      </div>
      <div class="row">
        <img src="https://file.chskj.cn/contactIntroduce.png" alt />
        <a @click="openPdf">点击查看电子版公司介绍</a>
      </div>
      <div class="row">
        <img src="https://file.chskj.cn/contactEmail.png" alt />
        <a class="email" href="mailto:Service@chskj.cn">Service@chskj.cn</a>
      </div>
      <div class="row">
        <img src="https://file.chskj.cn/contactPhone.png" alt />
        <span>15902055515 刘先生</span>
      </div>
      <div class="row">
        <img src="https://file.chskj.cn/contactLocation.png" alt />
        <span>广州市越秀区东风东路703号创昂文化大楼5楼</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        company: "",
        city: "",
        industry: "",
        phone: "",
        email: "",
        content: "",
      },
      codeInput: "",
      code: "",
      isSubmit: false,
      validateObj: {
        name(name) {
          if (name.length > 0 && name.length < 21) {
            return true;
          }
          return false;
        },
        company(company) {
          if (company.length > 0 && company.length < 21) {
            return true;
          }
          return false;
        },
        city(city) {
          if (city.length > 0 && city.length < 16) {
            return true;
          }
          return false;
        },
        industry(industry) {
          if (industry.length > 0 && industry.length < 21) {
            return true;
          }
          return false;
        },
        phone(phone) {
          let telStr =
            /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
          if (telStr.test(phone)) {
            return true;
          }
          return false;
        },
        email(email) {
          let emailStr = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
          if (emailStr.test(email)) {
            return true;
          }
          return false;
        },
        content(content) {
          if (content.length > 0 && content.length < 301) {
            return true;
          }
          return false;
        },
      },
    };
  },
  mounted() {
    this.generateCode();
  },
  methods: {
    refreshCode() {
      this.generateCode();
    },
    generateCode() {
      let tempCode = [];
      for (let i = 0; i < 4; i++) {
        let index = Math.floor(Math.random() * 62);
        let codeArr = this.generateCodeArr();
        tempCode.push(codeArr[index]);
      }
      this.code = tempCode.join("");
      document.getElementsByClassName("code")[0].innerText = this.code;
    },
    generateCodeArr() {
      let arr = [];
      for (let j = 97; j <= 122; j++) {
        arr.push(String.fromCharCode(j));
      }
      for (let j = 65; j <= 90; j++) {
        arr.push(String.fromCharCode(j));
      }
      for (let k = 0; k < 10; k++) {
        arr.push(k);
      }
      return arr;
    },
    checkCodeIsCorrect() {
      let inputCode = this.codeInput;
      if (inputCode === "") {
        return "null";
      }
      if (inputCode === this.code) {
        return true;
      }
      return false;
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.codeInput = "";
      this.isSubmit = false;
      this.refreshCode();
    },
    onInput() {
      if (this.form.phone.length > 11)
        this.form.phone = this.form.phone.slice(0, 11);
    },
    async submitForm() {
      this.isSubmit = true;
      let flag = true;
      for (let key in this.form) {
        if (!this.validateObj[key](this.form[key])) {
          {
            flag = false;
            this.codeInput = "";
            break;
          }
        }
      }
      if (flag) {
        if (this.checkCodeIsCorrect() === true) {
          this.$store.commit("LOADING", ["表单提交中请稍后", "tips"]);
          const res = await this.api.commitFroms(this.form);
          if (res.code === 200) {
            this.resetForm();
            this.$store.commit("LOADING", ["", ""]);
            this.$store.commit("SET_ISSHOW", ["提交成功", "tips"]);
            setTimeout(() => {
              this.$store.commit("SET_ISSHOW", ["", ""]);
            }, 1500);
          } else {
            console.log(res.msg);
          }
        } else {
          this.refreshCode();
          this.codeInput = "";
          console.log("验证码错误");
        }
      } else {
        this.refreshCode();
        console.log("请输入完整的表单！");
      }
      return;
    },
    openPdf() {
      window.open(
        "https://file.chskj.cn/%E5%B9%BF%E4%B8%9C%E5%88%9B%E4%B8%96%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%E7%AE%80%E4%BB%8B.pdf"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.consulting {
  background: #305587;
  padding: 48px 4% 0 4%;
  span.contact-title {
    height: 64px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 64px;
  }
  p.description {
    height: 56px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
  }
  span.require {
    height: 28px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 28px;
    padding-top: 8px;
  }
  .form {
    .row {
      &:nth-child(2n) {
        margin-top: 8px;
      }
      &:nth-child(2n + 1) {
        margin-top: 16px;
      }
      span.title {
        width: 78px;
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
      span.illegal {
        color: #fd6c6b;
        margin-left: 30px;
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 28px;
      }
      span.textLength {
        display: none;
      }
      span.textVisible {
        margin-left: 30px;
        display: inline;
        color: #ffffff;
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 28px;
      }
      input.inputDiv {
        width: 100%;
        height: 40px;
        border-radius: 2px;
        padding: 0 12px;
        box-sizing: border-box;
        &::-webkit-input-placeholder {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 24px;
        }
        &:focus {
          outline-color: transparent;
          outline-width: 0;
        }
      }
      textarea.inputDiv {
        width: 100%;
        height: 120px;
        background: #ffffff;
        border-radius: 2px;
        padding: 8px 12px;
        box-sizing: border-box;
        &::-webkit-input-placeholder {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 24px;
        }
        &:focus {
          outline-color: transparent;
          outline-width: 0;
        }
      }
      .emptyInput {
        box-shadow: 0px 0px 8px 2px #fd6c6b;
      }
    }
    .row.inputArea-1 {
      display: flex;
      .codeDiv {
        flex: 1;
      }
      .code {
        width: 112px;
        height: 40px;
        background: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-left: 9px;
      }
    }
    .row.submit {
      margin-top: 24px;
      button.submitButton {
        width: 100%;
        height: 48px;
        background: #1890ff;
        border-radius: 2px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .methods {
    margin-top: 48px;
    .title {
      height: 28px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
    }
    .row {
      display: flex;
      align-items: center;
      margin-top: 16px;
      img {
        width: 28px;
        height: 28px;
        object-fit: cover;
      }
      a {
        height: 28px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        margin-left: 8px;
        text-decoration: underline;
      }
      .email {
        text-decoration: none;
      }
      span {
        height: 28px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        margin-left: 8px;
      }
      &:nth-last-child(1) {
        padding-bottom: 24px;
      }
    }
  }
}
</style>
