<template>
  <div class="tech-swiper">
    <swiper class="techSwiper" ref="techSwiperRef" :options="swiperOptions">
      <div class="swiper-pagination" slot="pagination"></div>
      <swiper-slide
        class="swiper-slide"
        v-for="(item, index) in productList"
        :key="index"
      >
        <div class="product-title">{{ item.title }}</div>
        <div class="product-divider"></div>
        <div class="product-content">{{ item.content }}</div>
        <!-- linkSwiper -->
        <swiper
          class="linkSwiper"
          ref="linkSwiperRef"
          :options="linkSwiperOptions"
        >
          <swiper-slide
            class="link-swiper-slide"
            v-for="(o, index) in item.link"
            :key="index"
          >
            <img class="link-img" :src="o.pic" alt />
            <div class="link-text">{{ o.text }}</div>
          </swiper-slide>
        </swiper>
      </swiper-slide>
    </swiper>
    <div class="more">
      <div class="more-text">了解更多</div>
      <div class="more-img" @click="clickNavs('.solutions')">
        <img src="@/assets/icon/ArrowDown-Whtie.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      productList: [
        {
          id: 1,
          title: "大数据",
          pic: "https://file.chskj.cn/bigData.png",
          href: "#bigData",
          link: [
            {
              pic: "https://file.chskj.cn/bigData (1).png",
              text: "Bot Factory™ 平台",
            },
            {
              pic: "https://file.chskj.cn/bigData (2).png",
              text: "AICC 平台",
            },
            {
              pic: "https://file.chskj.cn/bigData (3).png",
              text: "Gemini 平台",
            },
            {
              pic: "https://file.chskj.cn/bigData (4).png",
              text: "NLP 平台",
            },
          ],
          content: `创世科技网络大数据应用管理平台是一款帮助用户实现资产测绘及运营管理的产品。以数据仓库(DW)技术、联机分析处理(OLAP)技术、前端分析展示技术和数据挖掘(DM)技术构建。随着数字化转型的不断推进以及云计算、物联网、工业互联网的快速发展，组织内部连接和管理的资产数量和种类急速增长。平台以安全大数据分析技术为基础，通过主动探测机制，依托可视化技术实现网络安全风险的预警及信息通报，实现网络管理闭环。`,
        },
        {
          id: 2,
          title: "信息安全",
          pic: "https://file.chskj.cn/informationSafety.png",
          href: "#bigData",
          link: [
            {
              pic: "https://file.chskj.cn/informationSafety (1).png",
              text: "安全审计",
            },
            {
              pic: "https://file.chskj.cn/informationSafety (2).png",
              text: "入侵检测",
            },
            {
              pic: "https://file.chskj.cn/informationSafety (3).png",
              text: "代码检测",
            },
            {
              pic: "https://file.chskj.cn/informationSafety (4).png",
              text: "邮件检测",
            },
            {
              pic: "https://file.chskj.cn/informationSafety (5).png",
              text: "病毒预警",
            },
            {
              pic: "https://file.chskj.cn/informationSafety (6).png",
              text: "安全评估",
            },
          ],
          content: `创世科技自主研发基于大数据架构的数据安全分析平台。可实现数字管理，实现对数据流转的动态监测，发现系统共享API风险；可实现对数据安全设备告警的集中管控。借助平台的算法和算力有效降低人工分析成本，提升行业用户对数据安全风险管理工作的效率。`,
        },
        {
          id: 3,
          title: "人工智能",
          pic: "https://file.chskj.cn/artificialIntelligence.png",
          href: "#man",
          link: [
            {
              pic: "https://file.chskj.cn/artificialIntelligence (1).png",
              text: "自然语言处理",
            },
            {
              pic: "https://file.chskj.cn/artificialIntelligence (2).png",
              text: "视觉计算",
            },
            {
              pic: "https://file.chskj.cn/artificialIntelligence (3).png",
              text: "深度学习",
            },
            {
              pic: "https://file.chskj.cn/artificialIntelligence (4).png",
              text: "语音识别",
            },
            {
              pic: "https://file.chskj.cn/artificialIntelligence (5).png",
              text: "知识工程",
            },
          ],
          content: `
            创世的"AI算法"以深度学习为技术，面向广泛"图像或视频"的分析和理解，以解决真实问题为向导，追求软硬结合的极致性能，向客户交付工业级的产品和服务。基于深度学习、海量数据以及自研开源框架，深度结合落地场景，技术覆盖人脸/人体检测、跟踪、关键点定位、人脸识别、人脸聚类、大规模检索、活体判断、人脸属性等，为城市治理、公共安全、园区管理、实名认证、通行考勤等业务场景提供高效、准确的技术。`,
        },
        {
          id: 4,
          title: "云计算",
          pic: "https://file.chskj.cn/cloudComputing.png",
          href: "#cloud",
          link: [
            {
              pic: "https://file.chskj.cn/cloudComputing (1).png",
              text: "医疗",
            },
            {
              pic: "https://file.chskj.cn/cloudComputing (2).png",
              text: "政务",
            },
            {
              pic: "https://file.chskj.cn/cloudComputing (3).png",
              text: "电商",
            },
            {
              pic: "https://file.chskj.cn/cloudComputing (4).png",
              text: "教育",
            },
            {
              pic: "https://file.chskj.cn/cloudComputing (5).png",
              text: "O2O",
            },
          ],
          content: `创世云计算基于云计算部署进行技术搭建，云计算将促进垂直行业的发展，云科技支持服务为不同规模和技术能力的客户提供高效支持，帮助客户应用我们的产品和功能来实现各项业务需求。云计算虚拟化技术，分布式资源管理技术，在并行编程模式下，并发处理、容错、数据分布、负载均衡等。以SaaS作为主要服务形式。`,
        },
        {
          id: 5,
          title: "智能终端",
          pic: "https://file.chskj.cn/IntelligentTerminal.png",
          href: "#endPoint",
          link: [
            {
              pic: "https://file.chskj.cn/IntelligentTerminal (1).png",
              text: "分布语音识别技术",
            },
            {
              pic: "https://file.chskj.cn/IntelligentTerminal (2).png",
              text: "文本转语音(TTS)",
            },
            {
              pic: "https://file.chskj.cn/IntelligentTerminal (3).png",
              text: "应用技术",
            },
            {
              pic: "https://file.chskj.cn/IntelligentTerminal (4).png",
              text: "Voice XML",
            },
            {
              pic: "https://file.chskj.cn/IntelligentTerminal (5).png",
              text: "自动语音识别(ASR)",
            },
          ],
          content: `创世智能终端基于分布语音识别(DSR)技术、文本转语音(TTS)、电话语音接入、TTS/ASR资源等技术，基于场景检测识别和领先的AI算法能力，为客户提供智慧园区等可视化业务应用，提高企业人员管理效率，降低运营管理成本。最终，帮助企业实现数字化管理模式的成功转型。`,
        },
        {
          id: 6,
          title: "三维建模",
          pic: "https://file.chskj.cn/3DModeling.png",
          href: "#endPoint",
          link: [
            {
              pic: "https://file.chskj.cn/3DModeling (1).png",
              text: "数据自动采集",
            },
            {
              pic: "https://file.chskj.cn/3DModeling (2).png",
              text: "三维数据处理",
            },
            {
              pic: "https://file.chskj.cn/3DModeling (3).png",
              text: "三维模型编辑",
            },
            {
              pic: "https://file.chskj.cn/3DModeling (4).png",
              text: "三维成果发布",
            },
          ],
          content: `创世三维建模，提供一站式的数据自动采集、三维数据处理、三维模型编辑和移动互联网三维成果发布的全套工作流程与服务，服务于地空影像的三维解算、多源异构的影像点云混合建模、动态建模及智能化BIM建模。上传图片至平台即可自动生成高质量三维模型。模型可以在线分享，任何带有浏览器的系统即可打开。平台还集成了强大的三维后期编辑和测量工具。平台封装了三维重建和三维展示引擎，可开放API 与SDK，用户可根据业务需求灵活地进行二次开发或定制开发。`,
        },
        {
          id: 7,
          title: "物联网",
          pic: "https://file.chskj.cn/IOT.png",
          href: "#endPoint",
          link: [
            {
              pic: "https://file.chskj.cn/IOT (1).png",
              text: "全链路设备开发",
            },
            {
              pic: "https://file.chskj.cn/IOT (2).png",
              text: "可扩展场景服务",
            },
            {
              pic: "https://file.chskj.cn/IOT (3).png",
              text: "低成本和云托管",
            },
          ],
          content: `创世物联网通过智能感知、识别技术、普适计算、泛在网络的融合应用，车联网、智能电网、智能家居、安防监控、远程医疗。支持 WiFi、蜂窝、LoRa、蓝牙等通信制式设备上云，提供多平台设备SDK 及定制模组接入能力及云端一站式设备上云开发服务，提升各行业设备上云效率。为用户的设备智能化提供便捷、丰富的增值服务能力。提供在线语音识别、 TRTC、图像识别能力、位置服务、语音技能能力，满足设备智能化在不同场景下的服务需要。`,
        },
      ],
      swiperOptions: {
        speed: 1000,
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      linkSwiperOptions: {
        speed: 1000,
        slidesPerView: 3,
        slidesPerGroup: 1,
        loop: false,
      },
    };
  },
  methods: {
    // 头部导航栏点击事件
    clickNavs(path) {
      let top = 0;
      if (path !== 0) {
        const navHeight = document.querySelector(".header").clientHeight - 1;
        const scrollY = document.querySelector(path).offsetTop;
        top = scrollY - navHeight;
      }
      window.scrollTo({
        left: 0,
        top,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tech-swiper {
  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        margin-top: 24px;
        .product-title {
          width: 84%;
          height: 48px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          padding: 0 8%;
        }
        .product-divider {
          width: 32px;
          height: 4px;
          background: #ffffff;
          border-radius: 2px;
          margin: 8px 8% 0 8%;
        }
        .product-content {
          width: 84%;
          height: 252px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          padding: 8px 8% 16px 8%;
        }
        .linkSwiper {
          background: rgba(0, 0, 0, 0.35);
          height: 148px;
          .swiper-wrapper {
            display: flex;
            .link-swiper-slide.swiper-slide {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin-top: 0;
              img {
                width: 64px;
                height: 64px;
                object-fit: cover;
              }
              .link-text {
                width: 76px;
                height: 36px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 18px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
    /deep/.swiper-pagination {
      top: 0;
      bottom: auto;
      .swiper-pagination-bullet {
        display: inline-block;
        opacity: 1;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.25);
        width: 8px;
        height: 8px;
      }
      .swiper-pagination-bullet-active {
        width: 24px;
        height: 8px;
        background: rgba(255, 255, 255, 0.85);
        border-radius: 4px;
      }
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .more-text {
      width: 285px;
      height: 48px;
      background: #ffffff;
      box-shadow: 0px 5px 15px 0px rgba(48, 85, 135, 0.45);
      border-radius: 8px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0068da;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
    }
    .more-img {
      margin: 20px 0 0 0;
      width: 28px;
      height: 28px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
