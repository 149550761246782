<template>
  <div class="BeforeCooperate">
    <div class="text">
      <div class="title-container" data-aos="fade-up">
        <span class="name">广东创世科技有限公司</span>
        <span class="title"
          >AI + 大数据 + 云计算，融合构建政校企数字化平台</span
        >
      </div>
      <div class="inline"></div>
      <div class="detail">
        <span class="span">
          广东创世科技有限公司（同时为
          <span class="span-name">数字科学实验室</span>
          的法人主体），成立于2017年，
          致力提供产业的整体技术解决方案，产学研成果转化，助力企业技术及产品研发升级。
        </span>
        <span>
          数字科学实验室，由华南理工大学计算机学院联合华南理工大学广州校友会、
          欢聚集团(NASDAQ:YY)、广东创世科技等企业联合共建，
          由华工计算机学院院长、欧洲科学院外籍院士、欧洲科学与艺术院院士陈俊龙教授担任主任，
          学院党政领导、欢聚集团、京华信息、有米科技、创世科技、广州校友会等单位负责人担任荣誉主任和副主任。
        </span>
      </div>
      <div class="more">
        <div class="icon">
          <img :src="require('@/assets/icon/ArrowRightWhite@2x.png')" alt />
        </div>
        <span class="more-span">了解更多</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.BeforeCooperate {
  position: relative;
  background: url(https://file.chskj.cn/companProfile.jpg) no-repeat center;
  background-size: cover;
  width: 100%;
  .text {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 48px 32px 0 32px;
    color: #fff;
    .name {
      font-size: 30px;
      font-weight: 600;
      line-height: 56px;
    }
    .title {
      font-size: 13px;
      line-height: 28px;
    }
    .inline {
      margin: 16px 0;
      width: 32px;
      height: 4px;
      background: #ffffff;
      border-radius: 2px;
    }
    .detail {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      line-height: 24px;
      .span {
        margin-bottom: 20px;
        .span-name {
          font-size: 16px;
        }
      }
    }
    .more {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin: 24px 0;
      .more-span {
        font-size: 18px;
        line-height: 28px;
        margin-right: 16px;
      }
      .icon {
        width: 38px;
        height: 38px;
        background: #0068da;
        border: 2px solid #ffffff;
        border-radius: 50%;
        line-height: 42px;
        text-align: center;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 28px;
          height: 28px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
