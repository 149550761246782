<template>
  <div class="footer">
    <p class="p">
      Copyright © 2017～2022 版权所有 广东创世科技有限公司
      <br />All Rights Reserved 粤ICP备2021110769号-1 
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  padding: 48px 0;
  width: 100%;
  background: #2b4c79;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  justify-content: center;
}
.p {
  height: 56px;
  font-size: 12px;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
}
</style>
