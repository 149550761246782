<template>
  <div class="product">
    <div class="title-container" data-aos="fade-up">
      <div class="english-title">PRODUCT</div>
      <div class="chinese-title">
        <div class="title">创世产品技术</div>
        <div class="desc">深耕IT前沿技术，打造先进平台及产品</div>
      </div>
    </div>
    <TechSwiper />
  </div>
</template>
<script>
import { TechSwiper } from "@/components";
export default {
  data() {
    return {};
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  created() {},
  methods: {},
  components: { TechSwiper },
};
</script>
<style lang="less" scoped>
.product {
  background: #305587;
  display: flex;
  flex-direction: column;
  .title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0 24px 0;
    .english-title {
      height: 80px;
      font-size: 64px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.1);
      line-height: 80px;
    }
    .chinese-title {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        height: 56px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 56px;
      }
      .desc {
        height: 36px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
  }
}
</style>
