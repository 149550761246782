<template>
  <div class="home">
    <Header />
    <Banner />
    <nums />
    <sixDiv />
    <product />
    <solutions />
    <product-exa />
    <BeforeCooperate />
    <Comutio />
    <DynamicInfo />
    <contact />
    <BottomNavs />
    <Footer />
    <Message />
    <Loading />
    <BottomFloat />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Banner from "./Banner.vue";
import nums from "./nums.vue";
import sixDiv from "./sixDIv.vue";
import product from "./product.vue";
import solutions from "./solutions.vue";
import ProductExa from "./productExa";
import BeforeCooperate from "./BeforeCooperate.vue";
import Comutio from "./Comutio.vue";
import DynamicInfo from "./DynamicInfo.vue";
import contact from "./contact.vue";
import BottomNavs from "./BottomNavs.vue";
import Footer from "./Footer.vue";
import { Message, Loading, BottomFloat } from "@/components";
export default {
  components: {
    ProductExa,
    Header,
    Banner,
    nums,
    sixDiv,
    product,
    solutions,
    Footer,
    BeforeCooperate,
    contact,
    Comutio,
    DynamicInfo,
    Loading,
    Message,
    BottomNavs,
    BottomFloat,
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 200vh;
}
</style>
