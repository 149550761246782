<template>
  <div class="comutio">
    <div class="title" data-aos="fade-up">
      <h1 class="h1">合作伙伴</h1>
      <p class="p">共同帮助众多客户获得效率成倍提升</p>
    </div>
    <div class="companies">
      <div
        class="lie"
        v-for="(item, index) in list"
        :key="index"
        data-aos="zoom-in"
        data-aos-delay="150"
      >
        <img class="img" :src="item.img" alt />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          img: "https://file.chskj.cn/company (1).png",
        },
        // {
        //   img: "https://file.chskj.cn/company (2).png",
        // },
        {
          img: "https://file.chskj.cn/company (3).png",
        },
        {
          img: "https://file.chskj.cn/company (4).png",
        },
        {
          img: "https://file.chskj.cn/company (5).png",
        },
        {
          img: "https://file.chskj.cn/company (6).png",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.comutio {
  width: 100%;
  box-sizing: border-box;
  padding: 48px 44px 0 44px;
  margin-bottom: 80px;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  .h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
  }
  .p {
    font-size: 16px;
    line-height: 36px;
  }
}
.companies {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 288px;
}
</style>
