<template>
  <div class="message">
    <!-- tips -->
    <div
      class="tips"
      v-show="msg.type === 'tips'"
      :style="msg.type === 'tips' ? 'opacity:1' : 'opacity:0;z-index:-1'"
    >
      <img src="@/assets/icon/success.png" alt />
      <span>{{ this.msg.content }}</span>
    </div>
    <!-- follow -->
    <div
      class="follow"
      v-show="msg.type === 'follow'"
      :style="msg.type === 'follow' ? 'opacity:1' : 'opacity:0;z-index:-1'"
    >
      <div class="follow_contaniner">
        <div class="title">
          <p>您好，我们是广东创世科技有限公司，</p>
          <p>欢迎添加关注我们的公众号，与我们联系</p>
        </div>
        <div class="divider"></div>
        <div class="middle">保存图片，微信扫一扫</div>
        <div class="wechatPic">
          <img src="https://file.chskj.cn/wechat.jpg" alt />
        </div>
        <div class="footer">数字科学实验室</div>
      </div>
      <div class="close" @click="close">
        <img src="@/assets/icon/Fork.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState(["isShow", "msg"]),
  },
  methods: {
    close() {
      this.$store.commit("SET_ISSHOW", ["", ""]);
      this.$store.commit("LOADING", ["", ""]);
    },
  },
};
</script>

<style lang="less" scoped>
.message {
  .tips {
    width: 150px;
    border-radius: 8px;
    position: fixed;
    display: flex !important;
    align-items: center;
    top: 14.5%;
    left: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 12px black;
    padding: 6px 20px;
    transform: translateX(-50%);
    transition: all 0.4s ease-in;
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
    span {
      flex: 1;
      margin-left: 5%;
      height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 28px;
    }
  }
  .follow {
    z-index: 9999;
    width: 91.5%;
    position: fixed;
    top: 15%;
    left: 50%;
    margin: 0 0 0 -45.75%;
    display: block !important;
    transition: all 0.3s ease-in-out;
    .follow_contaniner {
      width: 100%;
      background: #ffffff;
      box-shadow: 0 0.10667rem 0.32rem black;
      border-radius: 8px;
      transition: all 0.4s ease-in;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 27px 0 0 0;
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
        }
      }
      .divider {
        width: 286px;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        margin: 11px auto 0 auto;
      }
      .middle {
        height: 28px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
        text-align: center;
        margin-top: 13px;
      }
      .wechatPic {
        width: 160px;
        height: 160px;
        margin: 8px auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .footer {
        height: 28px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
        text-align: center;
        padding-bottom: 19px;
      }
    }
    .close {
      position: fixed;
      left: 50%;
      width: 42px;
      height: 42px;
      margin-top: 24px;
      transform: translateX(-50%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
