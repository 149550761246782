<template>
  <div
    class="loading"
    v-if="loadingMsg.type !== ''"
    @click="close"
    style="background: rgba(0, 0, 0, 0.55)"
  >
    <div class="loading-container" v-if="loadingMsg.type === 'tips'">
      <div class="msg">{{ this.loadingMsg.content }}</div>
      <div class="effect">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["isLoading", "loadingMsg"]),
  },
  methods: {
    close() {
      if (this.loadingMsg.type === "follow") {
        this.$store.commit("SET_ISSHOW", ["", ""]);
        this.$store.commit("LOADING", ["", ""]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  height: 100%;
  width: 100%;
  z-index: 9998;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading-container {
    display: flex;
    .msg {
      color: #ffffff;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .effect {
      width: 35px;
      height: 35px;
      margin-left: 5px;
      position: relative;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: lightblue;
        position: absolute;
        animation: load 1.04s ease infinite;
        @keyframes load {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0.2;
          }
        }
      }
      span:nth-child(1) {
        left: 0;
        top: 50%;
        margin-top: -3px;
        animation-delay: 0.13s;
      }
      span:nth-child(2) {
        left: 3px;
        top: 5px;
        animation-delay: 0.26s;
      }
      span:nth-child(3) {
        left: 50%;
        top: 0%;
        margin-left: -3px;
        animation-delay: 0.39s;
      }
      span:nth-child(4) {
        right: 3px;
        top: 5px;
        animation-delay: 0.52s;
      }
      span:nth-child(5) {
        right: 0;
        top: 50%;
        margin-top: -3px;
        animation-delay: 0.65s;
      }
      span:nth-child(6) {
        right: 3px;
        bottom: 5px;
        animation-delay: 0.78s;
      }
      span:nth-child(7) {
        left: 50%;
        bottom: 0;
        margin-left: -3px;
        animation-delay: 0.91s;
      }
      span:nth-child(8) {
        left: 3px;
        bottom: 5px;
        animation-delay: 1.04s;
      }
    }
  }
}
</style>
