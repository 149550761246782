<template>
  <div class="bottom-navs">
    <div class="title-container" data-aos="fade-up">
      <div class="logo">
        <img class="img" src="@/assets/icon/logo.png" />
        <span class="span">创世科技</span>
      </div>
      <div class="description">
        广东创世科技，打造数字科学人才高地，推进产学研成果转化，助力政企转型升级，为国家数字化战略布局倾尽全力，构建智慧社区，智慧城市，智慧社会。
      </div>
    </div>
    <div class="other">
      <div v-for="(item, index) in navList" :key="index" class="other-item">
        <div class="item-top" @click="clickItems(index)">
          <p class="title">{{ item.title }}</p>
          <img
            class="img"
            :class="item.isClick ? 'imgrotate180' : ''"
            src="@/assets/icon/ArrowDown.png"
            alt
          />
        </div>
        <ul class="childrens">
          <li
            class="childrens-item"
            v-for="(item1, index1) in item.children"
            :key="index1"
            :class="item.isClick ? 'click' : ''"
          >
            {{ item1.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          isClick: false,
          title: "产品",
          children: [
            { title: "大数据" },
            { title: "信息安全" },
            { title: "人工智能" },
            { title: "云计算" },
            { title: "智能终端" },
            { title: "三维建模" },
            { title: "物联网" },
          ],
        },
        {
          isClick: false,
          title: "行业方案",
          children: [
            { title: "政务数字化" },
            { title: "智能制造工业4.0" },
            { title: "智慧城市" },
            { title: "智慧教育" },
            { title: "校企合作产学研用" },
          ],
        },
        {
          isClick: false,
          title: "客户案例",
          children: [
            { title: "广州市政务服务数据管理系统" },
            { title: "广州12345政府服务热线项目" },
            { title: "机器人公司ERP" },
            { title: "三维实景智慧城市平台" },
            { title: "三维智慧文博平台" },
            { title: "剑桥英语考务系统" },
          ],
        },
        {
          isClick: false,
          title: "关于创世",
          children: [
            { title: "关于我们" },
            { title: "数字科学实验室" },
            { title: "最新动态" },
            { title: "加入我们" },
            { title: "联系我们" },
          ],
        },
      ],
    };
  },
  methods: {
    clickItems(index) {
      this.navList[index].isClick = !this.navList[index].isClick;
      this.navList.forEach((item, i) => {
        if (index !== i) {
          this.navList[i].isClick = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bottom-navs {
  padding: 48px 16px 1px 16px;
  background-color: #2b4c79;
  color: #ffffff;
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .img {
      width: 64px;
      margin-right: 16px;
    }
    .span {
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 42px;
      font-weight: 500;
      line-height: 64px;
    }
  }
  .description {
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 48px;
  }
  .other {
    .other-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 8px;
      position: relative;
      background: rgba(216, 216, 216, 0);
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
      .item-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          width: 28px;
          height: 28px;
          transition: all 0.3s ease-out;
        }
        .imgrotate180 {
          transform: rotate(180deg);
          -ms-transform: rotate(180deg); /* Internet Explorer */
          -moz-transform: rotate(180deg); /* Firefox */
          -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
          -o-transform: rotate(180deg); /* Opera */
        }
      }
      .title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 48px;
        height: 48px;
      }
      .childrens {
        display: flex;
        flex-direction: column;
        transition: all 0.5s ease-out;
        .childrens-item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(255, 255, 255, 0.75);
          line-height: 48px;
          height: 0px;
          opacity: 0;
          transition: all 0.3s ease-out;
        }
        .click {
          height: 48px;
          opacity: 1;
        }
      }
    }
  }
}
</style>
