<template>
  <div :class="{ header: true, isScroll }" :style="{ background: bg }">
    <div class="header-left">
      <img class="img" :src="logo" @click="clickNavs(0)" alt />
      <p class="text24">创世科技</p>
    </div>
    <div class="header-right" data-aos="fade-left">
      <div class="consults" @click="clickNavs('.consulting')">业务咨询</div>
      <img class="icon" :src="icon" alt style="display: none" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestory() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      isScroll: false,
      navIndex: 0,
    };
  },
  methods: {
    // 滚动监听函数
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isScroll = scrollTop ? true : false;
    },
    // 头部导航栏点击事件
    clickNavs(path) {
      let top = 0;
      if (path !== 0) {
        const navHeight = document.querySelector(".header").clientHeight - 1;
        const scrollY = document.querySelector(path).offsetTop;
        top = scrollY - navHeight;
      }
      window.scrollTo({
        left: 0,
        top,
        behavior: "smooth",
      });
    },
  },
  computed: {
    ...mapState(["isBlackBack"]),
    logo() {
      return this.isScroll
        ? require("@/assets/icon/logo1.png")
        : require("@/assets/icon/logo.png");
    },
    icon() {
      return this.isScroll
        ? require("@/assets/icon/black.png")
        : require("@/assets/icon/white.png");
    },
    bg() {
      return this.isBlackBack && !this.isScroll
        ? `linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%)`
        : "";
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0 16px;
  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 0, 0, 0.65) 0%,
  //   rgba(0, 0, 0, 0) 100%
  // );
  color: #fff;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-out;
  z-index: 1000;
  color: #fff;
  .header-left {
    display: flex;
    align-items: center;
    .text24 {
      font-size: 24px;
      font-weight: 500;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .consults {
      width: 80px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.85);
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
.isScroll {
  background: none;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  .header-right {
    .consults {
      background: #0068da;
      color: #fff;
    }
  }
}
.img {
  width: 42px;
  margin-right: 8px;
}
.icon {
  width: 28px;
  margin-left: 16px;
}
</style>
