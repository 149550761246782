<template>
  <div class="sixDiv">
    <div class="title" data-aos="fade-up">
      <span>助力您企业数字化升级</span>
      <span>
        数字科学实验室整合各界资源，发挥各方优势，
        <br />组成科研联合体，突破数字化技术系统高新技术，
        <br />打造科学发展和大数据人才高地，助力国家数字化进程
      </span>
    </div>
    <div class="item">
      <div class="itemDe" v-for="(item, index) in cardInfo" :key="index">
        <img class="bgImg" :src="item.pic" />
        <div class="content">
          <img class="co_img" :src="item.icon" />
          <div class="right">
            <div class="co_title">{{ item.title }}</div>
            <div class="co_content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="button">了解更多</div>
  </div>
</template>

<script>
export default {
  name: "sixDIv",
  data() {
    return {
      cardInfo: [
        {
          pic: "https://file.chskj.cn/sixDivImg (1).jpg",
          icon: "https://file.chskj.cn/sixDivImg (1).png",
          title: "资深行业专家",
          content:
            "依托华南理工大学计算机科学与工程学院数字科学实验室，汇聚计算机各领域资深行业专家教授，提升创新体系的整体效能",
        },
        {
          pic: "https://file.chskj.cn/sixDivImg (2).jpg",
          icon: "https://file.chskj.cn/sixDivImg (2).png",
          title: "先进技术支撑",
          content:
            "深耕人工智能、大数据、云计算以及物联网等多领域先进核心技术，聚焦关键应用领域，实现技术研发与产业化应用的深度融合",
        },
        {
          pic: "https://file.chskj.cn/sixDivImg (3).jpg",
          icon: "https://file.chskj.cn/sixDivImg (3).png",
          title: "有利政策支持",
          content:
            "广东省聚焦产学研协同创新、重点产业园区建设、科技创新载体搭建、辐射带动南岸发展等大学城片区重点建设内容",
        },
        {
          pic: "https://file.chskj.cn/sixDivImg (4).jpg",
          icon: "https://file.chskj.cn/sixDivImg (4).png",
          title: "高效企业管理方法",
          content:
            "运用现代企业高效管理方式，结合职权清晰式管理、危机式管理和破格管理，发挥创新主体的比较优势",
        },
        {
          pic: "https://file.chskj.cn/sixDivImg (5).jpg",
          icon: "https://file.chskj.cn/sixDivImg (5).png",
          title: "优质校企资源",
          content:
            "依托华南理工大学、中山大学等高校，联合华南理工大学广州校友会、欢聚集团、京华信息科技股份有限公司协同创新",
        },
        {
          pic: "https://file.chskj.cn/sixDivImg (6).jpg",
          icon: "https://file.chskj.cn/sixDivImg (6).png",
          title: "丰富高校人才",
          content:
            "与广州大学城各高校人才资源高度融合，聘请高校专家教授，吸纳高校优秀人才，有效探索新型创新模式",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.sixDiv {
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .title {
    text-align: center;
    margin-bottom: 6px;
    span:first-child {
      display: inline-block;
      font-size: 28px;
      line-height: 64px;
    }
    span:last-child {
      display: inline-block;
      font-size: 14px;
      line-height: 28px;
    }
  }
  .item {
    .itemDe {
      position: relative;
      width: 343px;
      height: 160px;
      margin-bottom: 16px;
      overflow: hidden;
      border-radius: 8px;
      box-shadow: 0 5px 15px 0 #30558773;
      &:nth-child(odd) {
        background: rgba(40, 89, 217, 0.85);
      }
      &:nth-child(even) {
        background: rgba(48, 85, 135, 0.85);
      }
      .bgImg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .content {
        width: 100%;
        height: 100%;
        position: relative;
        color: #fff;
        .co_img {
          position: absolute;
          left: 16px;
          top: 16px;
          width: 56px;
          height: 56px;
        }
        .right {
          position: absolute;
          top: 16px;
          bottom: 16px;
          right: 16px;
          left: 80px;
          .co_title {
            font-size: 28px;
            line-height: 56px;
          }
          .co_content {
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .button {
    margin-top: 8px;
    width: 285px;
    height: 48px;
    color: #fff;
    background: #007aff;
    text-align: center;
    border-radius: 8px;
    font-size: 18px;
    line-height: 48px;
    margin-bottom: 48px;
    box-shadow: 0px 5px 15px 0px rgba(38, 146, 255, 0.3);
  }
}
</style>
