import { render, staticRenderFns } from "./nums.vue?vue&type=template&id=46c95055&scoped=true"
import script from "./nums.vue?vue&type=script&lang=js"
export * from "./nums.vue?vue&type=script&lang=js"
import style0 from "./nums.vue?vue&type=style&index=0&id=46c95055&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c95055",
  null
  
)

export default component.exports